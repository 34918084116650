import React from 'react';

const Contact = () => {
  return (
    <div className="contact-page padding-large">
      <div className="container">
        <h1>Contact Us</h1>
        <p>Coming soon...</p>
      </div>
    </div>
  );
};

export default Contact; 