import React from 'react';

const Cart = () => {
  return (
    <div className="cart-page padding-large">
      <div className="container">
        <h1>Shopping Cart</h1>
        <p>Your cart is empty.</p>
      </div>
    </div>
  );
};

export default Cart; 