import React from 'react';

const Blog = () => {
  return (
    <div className="blog-page padding-large">
      <div className="container">
        <h1>Blog</h1>
        <p>Coming soon...</p>
      </div>
    </div>
  );
};

export default Blog; 