import React from 'react';

const Shop = () => {
  return (
    <div className="shop-page padding-large">
      <div className="container">
        <h1>Our Shop</h1>
        <p>Coming soon...</p>
      </div>
    </div>
  );
};

export default Shop; 